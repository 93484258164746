.signup-body {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Sign Up container */
.signup-container {
  width: 400px;
  margin: 90px auto;
  padding: 20px;
  background-color: #73a6e0;
  border-radius: 10px;
  text-align: center;
  opacity: 0.9;
}

@media only screen and (max-width: 500px) {
  .signup-container {
    width: 300px;
    margin: 110px auto;
  }
}
