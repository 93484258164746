.profile {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-repeat: repeat-x 50;
}

.filter-jobs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 300px; /* or any other height that you want */
}

form[type="filter-job"] {
  margin-top: 120px;
  height: 270px;
  position: absolute;
  align-items: center;
  align-content: center;
  width: 300px;
  justify-content: center;
  background-color: #f5f1f3;
  opacity: 0.9;
}

select {
  position: relative;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 18px;
  opacity: 0.5;
}

label[type="job-type"] {
  position: absolute;
  margin-top: 20px;
  text-align: center;
}

ul#jobs-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* or "space-between", "space-evenly", etc. */
  align-items: center;
  align-content: center;
  margin-top: 80px;
  padding-inline-start: 0px;
  margin-block-end: 0px;
}

form.jobs-available {
  width: 250px;
  height: 200px;
  flex-basis: 250px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
  margin-top: 8px;

  margin-left: 10px;
  margin-right: 10px;
}

ul#all-jobs-list {
  margin-top: 115px;
  padding-inline-start: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* or "space-between", "space-evenly", etc. */
}

form.all-jobs-available {
  width: 250px;
  height: 200px;
  flex-basis: 250px;
  margin: 20px;
  margin-bottom: 5px;
  justify-content: center; /* or "space-between", "space-evenly", etc. */

  margin-top: 8px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-button {
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modal-content {
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

#Light .modal-content {
  background: #f5f1f3;
}

#Dark .modal-content {
  background: #1d2226;
}

.modal-overlay.show {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.3s linear;
}
.modal-overlay.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0;
}

.modal-overlay .modal-content #close-modal {
  position: absolute;
  cursor: pointer;
  margin: 2px 2px;
  border-radius: 8px;
  top: 10px;
  right: 10px;
}

#Light .contact-button {
  background-color: white;
  color: #1d2226;
}

#Dark .contact-button {
  background-color: #1d2226;
  color: white;
  border: 2px solid #848884;
}

#Light .apply-link {
  color: #3d24da;
}

#Dark .apply-link {
  color: #2479da;
}

@media only screen and (max-width: 500px) {
  .filter-jobs {
    margin-top: 315px; /* or any other height that you want */
  }

  ul#jobs-list {
    margin-top: 100px;
  }

  form.all-jobs-available {
    padding-left: 45px;
    padding-right: 45px;
  }

  form.jobs-available {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media only screen and (max-height: 500px) {
  .filter-jobs {
    margin-top: 250px; /* or any other height that you want */
  }

  ul#jobs-list {
    margin-top: 80px;
  }

  ul#all-jobs-list {
    margin-top: 150px;
  }
}
