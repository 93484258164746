.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
}

.top {
  top: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}
