nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#Light nav {
  background-color: #fff;
}

#Dark nav {
  background-color: #1d2226;
}

nav ul {
  list-style: none;
  display: flex;
  margin-top: 5px;
  margin-bottom: auto;
  padding: 5px 0px;
}

nav li {
  margin: 0 6px 0px 10px;
  padding: 8px;
  border-radius: 8px;
  background: transparent;
  flex: 1 0 calc(8% - 30px);
}

nav a {
  align-items: center;
  text-decoration: none;
}

nav li:hover {
  transform: scale(1.03);
  transition: all 0.2s ease-in-out;
}

nav span {
  align-items: center;
  font-size: 150%;
  font-weight: 400;
}

#Light nav a {
  color: #848884;
}

#Light nav span {
  color: #848884;
}

#Dark nav a {
  color: #848884;
}

#Dark nav a:hover {
  color: white;
}

#Dark nav i:hover {
  color: white;
}

#Light nav a:hover {
  color: #000000e6;
}

#Light nav i:hover {
  color: #000000e6;
}

#Dark nav li.active a {
  color: white;
}

#Dark nav li.active i {
  color: white;
}

#Light nav li.active a {
  color: #000000e6;
}

#Light nav li.active i {
  color: #000000e6;
}
