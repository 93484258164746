#Dark form {
  background-color: #1d2226;
}

#Dark {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #000;
}

#Dark .login {
  /* background-image: url("../build/Dbackground.jpg"); */
  background-color: black;
}

#Dark .switch label {
  color: white;
}

#Dark h3 {
  color: white;
}

#Dark input {
  background-color: #1d2226;
  color: rgb(255, 255, 255);
  border: 2px solid rgb(20, 2, 2);
}

#Dark #welcomeUser {
  color: rgb(255, 255, 255);
}

#gender_male:hover,
#gender_female:hover,
#gender_other:hover {
  cursor: pointer;
  transform: scale(1.02);
  border-radius: 20px;
  background-color: #b0e4b9;
  border-color: #4caf50;
}

#Dark #gender_other {
  color: rgb(255, 255, 255);
  background-color: #1d2226;
  border: 2px solid rgb(20, 2, 2);
}

#Dark #gender_female {
  color: rgb(255, 255, 255);
  background-color: #1d2226;
  border: 2px solid rgb(20, 2, 2);
}

#Dark #gender_male {
  color: rgb(255, 255, 255);
  background-color: #1d2226;
  border: 2px solid rgb(20, 2, 2);
}

#Dark input[type="submit"] {
  color: white;
  /* background: linear-gradient(to right, #6be96f, #c7e80a); */
  background: linear-gradient(to right, #1a391b, #465204);
}

#Dark input[type="submit"]:hover {
  background: linear-gradient(to right, #927212, #910d0d);
}

#Dark #signup-link {
  color: white;
  background: linear-gradient(to right, #1a391b, #465204);
}

#Dark #back-to-login {
  color: white;
}

#Dark p {
  color: white;
}

#Dark h4 {
  color: white;
}

#Dark #signup-link:hover {
  background: linear-gradient(to right, #77e35e, #06700d);
}

#Dark select {
  background-color: #1d2226;
  color: rgb(253, 249, 249);
  border: 2px solid rgb(14, 13, 13);
  opacity: 0.9;
}

#Dark input:hover,
select:hover {
  background-color: #064010;
  border-color: #0f78c8;
}

#Dark select:hover {
  background-color: #064010;
  border-color: #0f78c8;
}

#Dark span.icon i {
  color: white;
}

#Dark #forgot-password-container a {
  color: white;
}

#Dark #update-password-container a {
  color: white;
}

#Dark .empty-Jobs {
  color: white;
}

#Dark .spinner {
  color: white;
}

#Dark ::placeholder {
  color: white;
}
