* {
  font-family: Arial, sans-serif;
}

#Light {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #f3f2ef;
}

.App {
  height: 2000px; /* set to a large enough value */
  width: 100%;
}

/* Form container */
form {
  width: 400px;
  margin: 180px auto;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  opacity: 0.9;
}

#Light form {
  background-color: #fff;
}

/* Background Image */
.login {
  position: absolute;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

#Light .login {
  /* background-image: url("../build/background.jpeg"); */
  background-color: rgb(234, 232, 204);
  overflow: auto;
}

/* Company header */
h3 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 34px;
  font-weight: bold;
}

h4 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: bold;
}

p {
  text-align: center;
  font-size: 18px;
}

#Light h3 {
  color: #000000e6;
}

#Light p {
  color: #000000e6;
}

/* Input labels */
label {
  font-size: 23px;
  margin-bottom: 5px;
  display: block;
  text-align: center;
  font-weight: bold;
}

/* Input fields */
input[type="text"],
input[type="email"],
input[type="date"],
input[type="number"],
input[type="url"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: auto;
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  padding-left: 40px;
}

input[type="radio"] {
  position: absolute;
  cursor: pointer;
  float: right;
}

select {
  transition: all 0.2s ease-in-out;
  padding-left: 40px;
  box-sizing: border-box;
  border-radius: 16px;
}

#gender_male,
#gender_female,
#gender_other {
  width: 33%;
  display: inline-block;
  align-content: center;
  padding: 12px 20px;
  margin-bottom: auto;
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 18px;
  font-weight: normal;
}

#Light #gender_male,
#gender_female,
#gender_other {
  color: #848884;
  background-color: #fff;
  border: 2px solid #848884;
}

#Light input[type="date"] {
  color: #848884;
}

#Light input {
  background-color: #fff;
  border: 2px solid #848884;
}

#Light select {
  background-color: #fff;
  color: #848884;
  border: 2px solid #848884;
}

/* Input hover color change*/
input[type="text"]:hover,
input[type="email"]:hover,
input[type="date"]:hover,
input[type="password"]:hover,
input[type="number"]:hover,
input[type="url"]:hover,
select:hover {
  transform: scale(1.02);
  border-radius: 20px;
}

#gender_male:hover,
#gender_female:hover,
#gender_other:hover {
  cursor: pointer;
  transform: scale(1.02);
  border-radius: 20px;
  background-color: #b0e4b9;
  border-color: #4caf50;
}

#Light input:hover {
  background-color: #b0e4b9;
  border-color: #4caf50;
}

#Light select:hover {
  background-color: #b0e4b9;
  border-color: #4caf50;
}

/* Input hover animation*/
/* Add padding to input fields to make space for the icon */

/* Add css for the email and lock icon */
span.icon i {
  font-size: 18px;
  color: #848884;
  pointer-events: none;
  position: absolute;
  padding-left: 14px;
  padding-top: 49px;
  transform: translateY(-50%);
  z-index: 2;
}

.eye {
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  transform: translate(-120%, 60%);
  border: 0px;
  z-index: 2;
}

#Dark .eye {
  background-color: #1d2226;
  color: white;
}

#Light .eye {
  background-color: white;
  color: #848884;
}

.timeToHack {
  font-size: 15px;
  margin: 0px;
  animation: fadeDown 0.5s ease-in-out forwards;
}

@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.time {
  font-weight: bold;
}

#Light .timeToHack {
  color: #848884;
}

#Light span.icon i {
  color: #848884;
}

label.label-text input {
  padding-left: 40px;
}

/* Error message */
.errorValue {
  margin-top: 10px;
  color: red;
  font-size: 18px;
  font-weight: bold;
}

/* Success message */
.successValue {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

#Light .successValue {
  color: #205c22;
}

#Dark .successValue {
  color: #308d33;
}

/* Submit button */
button[type="submit"] {
  width: 100%;
  padding: 14px 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  color: #000000e6;
  background: linear-gradient(to right, #6be96f, #c7e80a);
}

/* Login Hover Transition effect */
button[type="submit"]:hover {
  transform: scale(1.01);
  border-radius: 20px;
  background: linear-gradient(to right, #77e35e, #06700d);
}

#back-to-login:hover {
  transform: scale(1.3);
}

/* Sign up link */
#signup-link {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  z-index: 3;
}

#Light #signup-link {
  color: #000000e6;
  background: linear-gradient(to right, #6be96f, #c7e80a);
}

#Light #back-to-login {
  color: #848884;
}

#Light #signup-link:hover {
  background: linear-gradient(to right, #77e35e, #06700d);
}

/* Login Hover Transition effect */

#signup-link:hover {
  transform: scale(1.03);
  border-radius: 20px;
}

/* ----------------------------- */
/* Error animation */
.vibrate-error {
  animation: vibrate 0.2s ease-in-out;
  background-color: var(--error-color);
}

.transparent-red-screen {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(247, 12, 12, 0.3);
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.switch {
  position: absolute;
  display: flex;
  width: 28vw;
  margin-top: 20px;
  margin-left: 30px;
  justify-content: left;
  align-items: center;
  z-index: 0;
}

#Light .switch label {
  color: #000000e6;
}

body {
  display: block;
  margin: 0px;
}

#welcomeUser {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 23px;
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  #gender_male,
  #gender_female,
  #gender_other {
    padding: 12px 1px;
    font-size: 15px;
  }

  form {
    width: 300px;
  }

  #signup-link {
    top: 40px;
  }
}

@media only screen and (max-height: 500px) {
  form {
    margin: 100px auto;
  }
}
